<template>
    <div class="display">
        <p class="display-wechat-title">企业微信推送配置</p>
        <div class="display-wechat-configuration">
            <div class="display-wechat-configuration-association">
                <p class="display-wechat-configuration-association-title">关联的企业微信群webhook：</p>
                <p class="display-wechat-configuration-association-content">{{ model.weixinWebhookUrl }}</p>
            </div>
            <div class="display-wechat-configuration-pdf">
                <p class="display-wechat-configuration-pdf-title">发送PDF文件：</p>
                <el-switch class="display-wechat-configuration-pdf-switch" v-model="isPushPDF" active-color="#6DA4D8"
                    inactive-color="#DCDFE6" disabled></el-switch>
            </div>
            <div class="display-wechat-configuration-word">
                <p class="display-wechat-configuration-word-title">发送文字介绍：</p>
                <el-switch class="display-wechat-configuration-word-switch" v-model="isPushWord" active-color="#6DA4D8"
                    inactive-color="#DCDFE6" disabled></el-switch>
                <p class="display-wechat-configuration-word-tip">例如：小明2月9日报告已生成，请查收！</p>
            </div>
            <div class="display-wechat-configuration-frequency">
                <p class="display-wechat-configuration-frequency-title">推送频次：</p>
                <p class="display-wechat-configuration-frequency-content">{{ this.weixinWebhookFrequentType }}</p>
            </div>
        </div>
        <p class="display-message-title">短信推送配置</p>
        <div class="display-message-configuration">
            <div class="display-message-configuration-association">
                <p class="display-message-configuration-association-title">关联的短信模版：</p>
                <p class="display-message-configuration-association-content">{{ this.smsTemplate }}</p>
            </div>
            <div class="display-message-configuration-frequency">
                <p class="display-message-configuration-frequency-title">推送频次：</p>
                <p class="display-message-configuration-frequency-content">{{ this.smsFrequentType }}</p>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        model: Object,
    },
    data() {
        return {
            isPushPDF: false,
            isPushWord: false,
            smsFrequentType: "关闭",
            weixinWebhookFrequentType: "关闭",
            smsTemplate:"温馨提醒，您的监测已结束，请及时将设备归还至医院以便出具报告！"
        }
    },
    mounted() {
        
        this.initValue()

    },
    methods: {

        initValue() {

            if (this.model.weixinWebhookSendPdf == "1") {
                this.isPushPDF = true;

            } else {
                this.isPushPDF = false;
            }

            if (this.model.weixinWebhookSendText == "1") {
                this.isPushWord = true;
            } else {
                this.isPushWord = false;
            }

            switch (this.model.smsFrequentType) {
                case "1":
                    this.smsFrequentType = "仅完整报告第一次生成时推送"
                    break;
                case "2":
                    this.smsFrequentType = "生成完整报告和报告更新时都推送"
                    break;
                default:
                    this.smsFrequentType = "关闭"
                    break;
            }

            switch (this.model.weixinWebhookFrequentType) {
                case "1":
                    this.weixinWebhookFrequentType = "仅完整报告第一次生成时推送"
                    break;
                case "2":
                    this.weixinWebhookFrequentType = "生成完整报告和报告更新时都推送"
                    break;
                default:
                    this.weixinWebhookFrequentType = "关闭"
                    break;
            }
            if (this.model.smsTemplateId == "14") {
                this.smsTemplate = "温馨提醒，您的监测已结束，请及时将设备归还至医院以便出具报告！"
            }
        }
    },
    watch: {
        model(newValue) {
           
            if (newValue.weixinWebhookSendPdf == "1") {
                this.isPushPDF = true;

            } else {
                this.isPushPDF = false;
            }

            if (newValue.weixinWebhookSendText == "1") {
                this.isPushWord = true;
            } else {
                this.isPushWord = false;
            }

            switch (newValue.smsFrequentType) {
                case "1":
                    this.smsFrequentType = "仅完整报告第一次生成时推送"
                    break;
                case "2":
                    this.smsFrequentType = "生成完整报告和报告更新时都推送"
                    break;
                default:
                    this.smsFrequentType = "关闭"
                    break;
            }

            switch (newValue.weixinWebhookFrequentType) {
                case "1":
                    this.weixinWebhookFrequentType = "仅完整报告第一次生成时推送"
                    break;
                case "2":
                    this.weixinWebhookFrequentType = "生成完整报告和报告更新时都推送"
                    break;
                default:
                    this.weixinWebhookFrequentType = "关闭"
                    break;
            }

            if (newValue.smsTemplateId == "14") {
                this.smsTemplate = "温馨提醒，您的监测已结束，请及时将设备归还至医院以便出具报告！"
            }

        }
    }
}
</script>


<style lang="scss" scoped>
.display {
    background-color: white;
    width: 100%;
    position: relative;

    &-wechat-title {
        color: black;
        font-size: rem(16);
        font-weight: bold;
        height: rem(22);
        margin-top: rem(16);
    }

    &-wechat-configuration {
        margin-top: rem(21);

        font-size: rem(14);
        color: black;

        &-association {
            height: rem(32);
            display: flex;
            align-items: center;

            &-title {
                width: rem(199);
            }
        }

        &-pdf {
            margin-top: rem(10);
            display: flex;

            &-switch {
                transform: scale(1.3);
                margin-left: rem(6);
                width: rem(52);
                height: rem(26);
            }

            &-title {
                height: rem(26);
                width: rem(199);
                display: flex;
                align-items: center;
            }
        }

        &-word {
            margin-top: rem(10);
            display: flex;
            align-items: center;
            &-switch {
                transform: scale(1.3);
                margin-left: rem(6);
                width: rem(52);
                height: rem(26);
            }

            &-title {
                height: rem(26);
                width: rem(199);
                display: flex;
                align-items: center;
            }

            &-tip{
                margin-left: rem(40);
            }
        }

        &-frequency {
            height: rem(32);
            display: flex;
            align-items: center;

            &-title {
                width: rem(199);
            }
        }
    }

    &-message-title {
        color: black;
        font-size: rem(16);
        font-weight: bold;
        height: rem(22);
        margin-top: rem(20);
    }

    &-message-configuration {
        font-size: rem(14);
        color: black;

        &-association {
            margin-top: rem(15);
            display: flex;
            align-items: center;
            height: rem(32);

            &-title {
                width: rem(199);
            }
        }

        &-frequency {
            margin-top: rem(10);
            height: rem(32);
            display: flex;
            align-items: center;

            &-title {
                width: rem(199);
            }
        }
    }


}
</style>